<template>
  <div>
    <!-- Loading -->
    <div class="loading-template" v-if="loading">
      <loading-indicator />
    </div>
    <main-header />
    <!-- Breadcrumb -->
    <div class="container">
      <div class="_flex _center">
        <nav aria-label="breadcrumb" style="height:40px!important">
          <ol class="breadcrumb  bg-transparent tiny">
            <li class="breadcrumb-item"><a href="#">Accueil</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              Mon panier
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <section class="container">
      <br />
      <div class="">
        <div class="text-center">
          <h2><b>Mon Panier</b></h2>
          <p v-if="authorized">
            ({{ items.length }} article{{ items.length > 1 ? "s" : "" }})
          </p>
        </div>
        <br/><br/>
        <section v-if="authorized && items.length">
          <table class="table table-bordered">
            <thead class="bg-light">
              <tr>
                <th scope="col">Œuvre</th>
                <th scope="col">Titre</th>
                <th scope="col">Artiste</th>
                <th scope="col">Qté</th>
                <th scope="col" class="text-md-right">Prix unitaire</th>
                <th scope="col" class="text-md-right">Total</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <th scope="row">
                  <div style="width:60px;background-color:#f8f8f8;height:75px;">
                    <img :src="item.data_artwork.images || ''" alt="" style="width:60px;height:75px;object-fit:contain"/>
                  </div>
                </th>
                <td>
                  <router-link :to="`/oeuvre/${item.data_artwork.slug}`">
                    {{ item.data_artwork.name || "" }}
                  </router-link>
                </td>
                <!-- Artist -->
                <td>{{item.data_artwork.data_artist.last_name}} {{item.data_artwork.data_artist.first_name}}</td>
                <!-- Qté -->
                <td>
                  <div class="quantity--update">
                    <div class="qty">{{ item.quantity }}</div>
<!--                    <div class="qty-controls">
                      <div @click="increment(item)">
                        <i class="fal fa-plus"></i>
                      </div>
                      <div @click="decrement(item)">
                        <i class="fal fa-minus"></i>
                      </div>
                    </div>-->
                  </div>
                  <!-- <select
                    class="form-control rounded-0"
                    style="max-width:100px"
                    v-model.number="item.quantity"
                  >
                    <option :value="n" v-for="n in 20" :key="n">{{ n }}</option>
                  </select> -->
                </td>
                <!-- Price -->
                <td class="text-md-right">${{ item.data_artwork.price | number }}</td>
                <!-- Total -->
                <td class="text-md-right">${{ calculateTotalPriceItem(item.data_artwork.price, item.quantity) | number }}</td>
                <!-- Action -->
                <td class="text-center">
                  <a @click="deleteCartItem(item.cart_id)" class="c-pointer" title="Supprimer cet article dans le panier">
                    <i class="fal fa-times title"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex flex-column align-items-md-end">
            <table class="table table-bordered table-total">
              <tr>
                <td>Total produits</td>
                <td class="text-right">${{ calculateTotalPriceAllItems() | number }}</td>
              </tr>
              <tr>
                <td>Total + TVA</td>
                <td class="text-right">${{ calculateTotalPriceAllItems() | number }}</td>
              </tr>
            </table>
            <br/>
            <button @click="$router.push('/commander')" class="btn btn-action black-outline border-2 text-uppercase px-4">
              Commander
            </button>
          </div>
        </section>
        <div class="d-flex w-100 flex-column align-items-center mt-8 mb-20" v-if="items.length < 1 && isLoggedIn">
          <img src="../assets/images/empty_cart.png" alt="" style="width:300px;object-fit:contain;"/>
          <br/><br/>
          <h4 style="max-width:400px;text-align:center;font-weight:600">Votre panier est vide</h4>
        </div>
        <!--  -->
        <not-authenticated />
      </div>
    </section>
    <br/><br/><br/>
    <main-footer/>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  data: () => ({
    loading: true,
    selectedQty: 1,
    items: [],
  }),
  computed: {
    ...mapGetters([
      "isLoggedIn",
      "cart",
      "cart_count",
      "totalPrices",
      "getToken",
    ]),
    count() {
      return this.cart_count || 0;
    },
    authorized() {
      return (this.getToken && this.items.length > 0);
    },
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([
        "axiosGetOneData",
        "tryAutoLogin",
        "axiosDeleteData",
    ]),
    retrieveCartItems(userId){
      this.axiosGetOneData({
        table: "retrieveCarts",
        table_id: userId,
      })
          .then( response => {
            this.items = response.data.data
            if(response.status === 200){
              this.$toast.success(response.data.message, {
                icon: "fas fa-2x fa-check-circle",
              });
            }else {
              this.$toast.error(response.data.message, {
                icon: "fas fa-2x fa-times-circle",
              });
            }
            this.loading = false;
          })
          .catch()
    },
    deleteCartItem(itemId){
      this.axiosDeleteData({
        table: "deleteCart",
        table_id: itemId,
      })
          .then( response => {
            if(response.status === 200){
              this.retrieveCartItems(this.getToken)
              this.$toast.success(response.data.message, {
                icon: "fas fa-2x fa-check-circle",
              });
            }else {
              this.$toast.error(response.data.message, {
                icon: "fas fa-2x fa-times-circle",
              });
            }
            this.loading = false;
          })
          .catch()
    },
    calculateTotalPriceItem(unitPrice, qty){
      return parseFloat(unitPrice) * parseInt(qty)
    },
    calculateTotalPriceAllItems(){
      let totalSum = 0
      this.items.map( (item) => {
        totalSum += (parseFloat(item.data_artwork.price) * parseInt(item.quantity))
      })
      return totalSum
    },
    increment(item) {
      return this.$store.dispatch("cartIncrement", item).catch(e => alert(e))
    },
    decrement(item) {
      return this.$store.dispatch("cartDecrement", item).catch(e => alert(e));
    },
  },
  mounted() {
    this.loading = true;
    this.$store
      .dispatch("fetchCart")
      .then(() => {
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
      });
  },
  created() {
    this.tryAutoLogin()
    if(this.getToken){
      this.retrieveCartItems(this.getToken)
    }
  }
};
</script>

<style scoped lang="scss">
.table-total {
  max-width: 320px;
}
@media screen and (max-width: 767px) {
  .table-total {
    max-width: 100%;
  }
}
</style>
